<template>
    <div @click="fetchCompanyModuleByFirstMenu($route.params.companyId, item.id)" style="cursor: pointer;">
        <!-- <div class="card-body"> -->
            <div class="avatar mb-1">
                <div class="avatar-content">
                    <img :src="item.icon" alt="" width="40">
                </div>
            </div>
            <!-- <h2 class="fw-bolder">{{ page.status }}</h2> -->
            <p style="height: 25px;" class="card-text">{{ item.name }}</p>
        <!-- </div> -->
    </div>
</template>

<script>
import handleSidebar from "@/services/modules/sidebar";
export default {
    name: 'ModuleCard',
    props: ['item'],
    setup() {
      const {
        fetchCompanyModuleByFirstMenu
      } = handleSidebar()

      return {
        fetchCompanyModuleByFirstMenu,
      }
    }
}
</script>

<style scoped>
    .pageCard:hover{
        padding: 10px;
        margin: 10px !important;
        background-color: #DBE7F2;
        box-sizing: border-box !important;
        cursor: pointer;

    }
    hr{
        margin: 0;
    }
     .avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #E7F3FF;
        /* background-color: #F0F2F5; */
        border: 2px solid #a2caf1;
    } 
    .avatar:hover {
        border: 4px solid #6baef1;
    }
    .avatar-content{
        margin: auto !important;
    }
    @media screen and (max-width: 400px){
        .pageCard{
            width: 50%;
        }
         .pageCard{
        padding: 0px;
        margin: 0px !important;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 0px;
        margin: 0px !important;
        background-color: #DBE7F2;
        border-radius: 10px;
        box-sizing: border-box !important; 
    }
    /* .pageCard:hover>.avatar {
        border: 100px solid red;
    } */
    }
</style>